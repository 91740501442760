<template>
  <div class="ka-reward-desc">
    <div>
      <p>
        <strong><span style="font-size: 15px;">卡类充值奖励:</span></strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong><span style="font-size: 15px;">黄金卡: </span></strong
        ><span style="font-size: 15px;"
          >188元(7天) 18880绑定元宝*3 /龙魂元神*10 /100积分*1 /麒麟元神*3
          /紫髓玉令*1</span
        >
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong><span style="font-size: 15px;">铂金卡:</span></strong
        ><span style="font-size: 15px;">
          588元(7天) 18880绑定元宝*10 /鲲鹏元神*5 /100积分*3 /女娲石*3
          /雕龙玉令*1</span
        >
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong><span style="font-size: 15px;">钻石卡:</span></strong
        ><span style="font-size: 15px;">
          888元(7天) 18880绑定元宝*12 /100点征战功勋*3 /100积分*5 /唤灵液*2
          /玄凤元神*20</span
        >
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong><span style="font-size: 15px;">星耀卡:</span></strong
        ><span style="font-size: 15px;">
          2222元(7天) 18880绑定元宝*25 /鲲鹏玉令*2 /至尊强化精华*10 /100积分*10
          /一箱宝珠*15</span
        >
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong><span style="font-size: 15px;">天龙终生VIP卡: </span></strong
        ><span style="font-size: 15px;"
          >999元(永久) 18880绑定元宝*3 /100积分*3 /龙魂元神* 12 /100征战功勋*2
          /200要诀残页</span
        >
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style="font-size: 15px; color: red;"
          >所有卡类充值都算网页累充不算游戏内充值!</span
        >
      </p>
      <section class="_editor">
        <p>
          <br />
        </p>
      </section>
      <p>
        <br />
      </p>
      <section class="_editor">
        <p>
          <br />
        </p>
      </section>
    </div>
    <div class="to-pay">
      <van-button block round type="danger" @click="goReward"
        >前往领取奖励</van-button
      >
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'

export default {
  name: 'KaRewardDesc',
  components: {
    'van-button': Button,
  },
  data() {
    return {}
  },
  methods: {
    goReward() {
      this.$router.push({ name: 'TlbbReward' })
    },
  },
}
</script>
<style lang="less" scoped>
.ka-reward-desc {
  padding: 0 20px;
}
.to-pay {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
}
</style>
