<template>
  <div class="ka-reward-desc">
    <div>
      <div>
        <section class="_editor">
          <section
            style="background-image: url(http://image.xingqingshe.com/image/www/0/750/2022/01/31/nWKoqsu881568598-.png);background-position: 0% 0%;background-size: 100%;background-repeat: repeat;"
            class="_sub_active"
          >
            <section class="">
              <img
                src="http://image.xingqingshe.com/image/www/0/750/2022/01/31/nWKm0iK408776536-.png"
                style="display:block;width:100%;"
              />
            </section>
            <section style="width: 200px;margin: -60px auto 0;" class="">
              <img
                src="http://image.xingqingshe.com/image/www/0/750/2022/01/31/nWKmIxw878034175-.png"
                style="display:block;width:100%;"
              />
            </section>
            <section style="margin: 0px auto 0;"></section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color:#c30d23"
                  ><span style="font-size: 21px;"
                    ><strong>玄武卡199元(月度限购)</strong></span
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >19.9W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >57.4W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >龙魂元神*200&nbsp; 90级保星符*60</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >炫彩金荷(30天)*1&nbsp; 小喇叭*20</span
                  ></span
                >
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color:#c30d23"
                  ><span style="font-size: 21px;"
                    ><strong>白虎卡588元(月度限购)</strong></span
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >58.8W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >114.2W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >妖虎元神*588&nbsp; 一箱宝珠*88</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >100积分*88&nbsp; 比翼鸟(3天)*1</span
                  ></span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color:#c30d23"
                  ><span style="font-size: 21px;"
                    ><strong>朱雀卡988元(月度限购)</strong></span
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >98.8W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >166.9W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >玄凤元神*888&nbsp; 比翼鸟(7天)*1</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span
                  style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                  >100积分*100&nbsp; 小喇叭*66</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color: #c30d23;"
                  ><strong
                    ><span style="font-size: 21px;"
                      >青龙卡2688元(月度限购)</span
                    ></strong
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >268.8W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >354.9W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >龙魂元神*999&nbsp;&nbsp;比翼鸟(7天)*1</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >100积分*188&nbsp; 天命神符*1</span
                  ></span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color: #c30d23;"
                  ><strong
                    ><span style="font-size: 21px;"
                      >麒麟卡5888元(月度限购)</span
                    ></strong
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >588.8W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >543.7W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >麒麟元神*999&nbsp;&nbsp;比翼鸟(30天)*1</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >100积分*266&nbsp; 征战功勋宝箱(10000点)*1</span
                  ></span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color: #c30d23;"
                  ><strong
                    ><span style="font-size: 21px;"
                      >烛龙卡8888元(月度限购)</span
                    ></strong
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >888.8W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >732.5W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >100积分*388&nbsp; 鲲鹏元神*999</span
                  ></span
                >
              </p>
              <p style="text-align: center;">
                <span style="color:#000000;font-family:PingFangTC-Ultralight"
                  ><span style="font-size: 15px;"
                    >龙魂元神精魄*200&nbsp; 90级保星符*333</span
                  ></span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color: #c30d23;"
                  ><strong
                    ><span style="font-size: 21px;"
                      >天龙至尊王者卡7777元</span
                    ></strong
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >777.7W<span
                      style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                      >不绑定元宝</span
                    ></span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >752W<span
                      style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                      >绑定元宝&nbsp; 激活相应金额奖励</span
                    ></span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span
                  style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                  >100积分*666&nbsp; 紫气金龙坐骑*1</span
                >
              </p>
              <p style="text-align: center;">
                <span
                  style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                  >元神丹*40&nbsp; 龙魂元神精魄*200</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color: #c30d23;"
                  ><strong
                    ><span style="font-size: 21px;"
                      >天龙无上荣耀卡9999元</span
                    ></strong
                  ></span
                >
              </p>
            </section>
            <section
              class="_editor"
              style="margin-right: auto; margin-left: auto; width: 86%;"
              data-width="86%"
            >
              <p style="text-align: center;">
                <strong
                  ><span
                    style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                    >999.9W不绑定元宝</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <strong
                  ><span
                    style="font-size: 15px; font-family: PingFangTC-Ultralight; color: #333;"
                    >940W绑定元宝&nbsp; 激活相应金额奖励</span
                  ></strong
                >
              </p>
              <p style="text-align: center;">
                <span
                  style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                  >100积分*888&nbsp; 坐骑大风*1</span
                >
              </p>
              <p style="text-align: center;">
                <span
                  style=";color:#000000;font-family:PingFangTC-Ultralight;font-size: 15px;"
                  >洛神饰*1&nbsp; 龙魂元神精魄*300</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color:#c30d23"
                  ><span style="font-size: 21px;"
                    ><strong>51欢乐卡151元</strong></span
                  ></span
                >
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>15.1W不绑定元宝</strong></span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>56.6W绑定元宝</strong>&nbsp; 激活相应金额奖励</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >劳动季礼盒*1&nbsp; 天命神符*1</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >圣贤玉*51&nbsp; 鲲鹏元神*51</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <section class="tool-border">
                <section></section>
                <section></section>
                <section></section>
                <section></section>
              </section>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="color:#c30d23"
                  ><span style="font-size: 21px;"
                    ><strong>劳动节金卡510元</strong></span
                  ></span
                >
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>51W不绑定元宝</strong></span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>188.8W绑定元宝</strong>&nbsp; 激活相应金额奖励</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >元神丹*51&nbsp; 龙魂元神*510</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >100积分*51&nbsp; 一箱宝珠（50个）*51</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style=";color:#c30d23;font-size: 21px;"
                  ><strong>劳动节铂金卡2510元</strong></span
                >
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>251W不绑定元宝</strong></span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>377.6W绑定元宝</strong>&nbsp; 激活相应金额奖励</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >比翼鸟（30天）*1&nbsp; 龙魂元神*510</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >麒麟元神*510&nbsp; 100积分*151</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style=";color:#c30d23;font-size: 21px;"
                  ><strong>劳动节钻石元7510元</strong></span
                >
              </p>
            </section>
            <section class="_editor">
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>751W不绑定元宝</strong></span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  ><strong>662.6W绑定元宝</strong>&nbsp; 激活相应金额奖励</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >麒麟元神*910&nbsp; 妖虎元神*910</span
                >
              </p>
              <p style="text-align: center;">
                <span style="font-size: 15px;"
                  >鲲鹏元神宝箱*510&nbsp; 100积分*910</span
                >
              </p>
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
            <section class="_editor">
              <p>
                <img
                  src="http://image.xingqingshe.com/2022/01/31/nWKni2r001254738-.gif"
                  alt=""
                  style="display: block; width: auto;"
                />
              </p>
            </section>
            <section
              class="_editor"
              data-support="96编辑器"
              data-style-id="29603"
              style="width: 84%; margin-right: auto; margin-left: auto;"
            >
              <section style="margin-top: 10px; margin-bottom: 10px;">
                <section
                  style="margin-top:-45px;background-color:#ffc2c2;border-radius:10px;"
                  class=""
                >
                  <section
                    style="padding:15px 15px 15px;color:#e1261d;transform: rotatez(0deg);"
                    class=""
                  >
                    <p style="letter-spacing: 2px;">
                      <span style="font-size: 15px;"
                        >该卡有充值元宝，算网页累计，算游戏内充值，仅在活动期间可以购买一次，活动时间为1月1号到2月15日结束，赠送物品一次性领取,每个账号终生只能购买一次，领取一次！</span
                      >
                    </p>
                  </section>
                </section>
              </section>
            </section>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
          </section>
        </section>
        <section class="_editor">
          <p>
            <br />
          </p>
        </section>
        <section class="_editor">
          <p>
            <br />
          </p>
        </section>
      </div>
    </div>
    <div class="to-pay">
      <van-button block round type="danger" :to="{ name: 'TlbbReward' }"
        >前往领取奖励</van-button
      >
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'

export default {
  name: 'HdRewardDesc',
  components: {
    'van-button': Button,
  },
  data() {
    return {}
  },
}
</script>
<style lang="less" scoped>
.ka-reward-desc {
  // padding: 0 20px;
}
.to-pay {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
}
</style>
